<template>
<div class="editpoi-container">
    <DialogWrapper v-if="dialogComponent != null" :component="dialogComponent" :callback="dialogCallback" :is-mobile="false" :data="poi" />
    <div class="top">

        <div class="fields">

            <div class="field">
                <p>Target</p>
                <LazyDropdown v-model="poi.target_id" :value="poi.target_id" endpoint="/targets" />
            </div>

            <div class="field">
                <p>{{ $t('common.city') }}</p>
                <LazyDropdown v-model="poi.city_id" :value="poi.city_id" endpoint="/places/cities" />
            </div>
            <div class="field">
                <p>{{ $t('poi.yearOfConstruction') }}</p>
                <vs-input type="text" v-model="poi.year_built" />
            </div>
            <div class="field">
                <p>{{ $t('poi.author') }}</p>
                <vs-input type="text" v-model="poi.author" />
            </div>
            <div class="field">
                <p>Website</p>
                <vs-input type="text" v-model="poi.website" />
            </div>
            <div class="field">
                <p>Indirizzo</p>
                <vs-input type="text" v-model="poi.street" />
            </div>
            <div class="field">
                <p>Lat</p>
                <vs-input type="text" v-model="poi.point.y" />
            </div>
            <div class="field">
                <p>Lon</p>
                <vs-input type="text" v-model="poi.point.x" />
            </div>

            <div class="field" v-if="poi.id != null">
                <p >{{ $t('common.language') }}</p>
                <vs-select v-model="lang" v-if="languages.length > 0">
                    <vs-option v-for="l in languages" :key="'lang-' + l.id" :label="l.name" :value="l.id">
                        {{ l.name }}
                    </vs-option>
                </vs-select>
            </div>

            <div class="field">
                <p>Nome</p>
                <vs-input v-if="lang != null" type="text" v-model="poi.poi_localization_infos.find(e => e.language_id == lang).name" />
            </div>

            <p>Descrizione</p>
            <textarea v-if="lang != null" v-model="poi.poi_localization_infos.find(e => e.language_id == lang).description">

            </textarea>

            <vs-checkbox block v-model="poi.place_to_discover">{{ $t('explore.placeToDiscover') }}</vs-checkbox>
            <vs-checkbox block v-model="poi.published">Pubblicato</vs-checkbox>

            <vs-button border size="large" @click="showGooglePlacesDialog">GOOGLE PLACES</vs-button>
            <vs-button size="large" :loading="loading" @click="editPoiEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
            <vs-button danger border size="large" :loading="deleting" @click="deletePoiEvent">{{ $t('common.delete').toUpperCase() }}</vs-button>
        </div>
    </div>

</div>
</template>

<script>
import { apiCall } from '../../utils/ApiMiddleware';
import GooglePlacesNearby from '../dialogs/GooglePlacesNearby.vue';
import LazyDropdown from '../LazyDropdown.vue';
import DialogWrapper from '../DialogWrapper.vue';
export default {
    name: "editPoi",
    props: {
        poi: {
            type: Object,
            required: true
        },
        editPoi: {
            type: Function,
            required: true
        },

        deleted: {
            type: Function,
            required: true
        },

        user: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: {
        LazyDropdown,
        DialogWrapper
    },
    data() {
        return {
            loading: false,
            languages: [],
            lang: null,
            deleting: false,

            dialogComponent: null,
        }
    },

    async mounted() {
        const response = await apiCall('GET', '/users/languages');
        if (response.status != 200) {
            this.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: this.$t('common.messages.somethingWentWrong'),
                text: 'Impossibile ottenere le lingue'
            })
            return;
        }
        this.languages = response.data.language;
        this.lang = this.languages.find(e => e.id == this.user.language.id).id

        if(this.poi.id == null){
            this.poi.poi_localization_infos = [{
                language_id: this.lang,
                name: '',
                description: ''
            }]
        }
    },

    methods: {
        showGooglePlacesDialog() {
            this.dialogComponent = GooglePlacesNearby;
        },

        dialogCallback(data, close) {
            if (close) {
                this.dialogComponent = null;
                return;
            }
        },

        async deletePoiEvent(){
            this.deleting = true;
            const response = await apiCall('DELETE', '/backoffice/pois/' + this.poi.id);
            this.deleting = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'POI eliminato con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.deleted();
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare il POI',
                    color: 'danger',
                    position: 'top-right'
                });
            }
            
        },

        async editPoiEvent() {
            this.poi.point.x = parseFloat(this.poi.point.x);
            this.poi.point.y = parseFloat(this.poi.point.y);
            if(this.poi.id == null){
                for(var k in this.languages){
                    if(this.languages[k].id == this.lang){
                        continue;
                    }
                    this.poi.poi_localization_infos.push({
                        language_id: this.languages[k].id,
                        name: this.poi.poi_localization_infos.find(e => e.language_id == this.lang).name,
                        description: this.poi.poi_localization_infos.find(e => e.language_id == this.lang).description
                    })
                }
            }
            this.loading = true;
            await this.editPoi(this.poi);
            this.loading = false;

        },

    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.editpoi-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .editpoi-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}

</style><style>
.editpoi-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.editpoi-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.editpoi-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}


</style>
