<template>
<div>
    <div class="googleplacesnearbydialog-container">
        <h2>Google Places Search</h2>
        <vs-input v-model="lat" type="number" label="LAT" style="margin-top: 20px;" block placeholder="41.3851" />
        <vs-input v-model="lng" type="number" label="LON" style="margin-top: 20px;" block placeholder="2.1734" />
        <div class="field">
                <p>Target</p>
                <LazyDropdown v-model="target_id" :value="target_id" endpoint="/targets" />
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-button size="large" border block @click="callback(null, true)">
                {{ $t('common.cancel') }}
            </vs-button>
            <vs-button size="large" block @click="search">
                {{ $t('common.continue') }}
            </vs-button>
        </div>
    </div>

</div>
</template>

    
<script>
import LazyDropdown from '@/components/LazyDropdown.vue'
import { apiCall } from '@/utils/ApiMiddleware';
export default {
    name: "googleplacesnearbydialog",
    props: {
        callback: Function,
        data: Object
    },
    components: {
        LazyDropdown
    },
    data() {
        return {
            lat: '',
            lng: '',

            target_id: null,
        }
    },
    mounted() {
        this.lat = this.data.point.y;
        this.lng = this.data.point.x;
        this.target_id = this.data.target_id;
    },

    methods: {
        async search() {
            const response = await apiCall('POST', '/backoffice/pictures', {
                latitude: this.lat,
                longitude: this.lng,
                target_id: this.target_id
            });
            console.log(response);
        }
    }
}
</script>

    
<style scoped>
p,
h3,
h2 {
    margin: 0;
}

.googleplacesnearbydialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style><style>
.googleplacesnearbydialog-container input {
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left !important;
    justify-content: flex-start;
}

.googleplacesnearbydialog-container input::-webkit-date-and-time-value {
    text-align: left;
}
</style>
